<template>
  <card :title="$t('series.info')">
    <form @submit.prevent="update" @keydown="form.onKeydown($event)">
      <alert-success :form="form" :message="$t('series.updated')" />

      <series-form :form="form"></series-form>

      <!-- Submit Button -->
      <div class="form-group row">
        <div class="col-md-9 ml-md-auto">
          <v-button :loading="form.busy" type="success">{{ $t('update') }}</v-button>
        </div>
      </div>
    </form>
  </card>
</template>

<script>
import Form from "vform"
import SeriesForm from "../../accounts/series/form"
import { mapGetters } from "vuex"

export default {
  scrollToTop: false,
  components: {
    "series-form": SeriesForm
  },
  data: () => ({
    form: new Form({
      thumbnailUrl: "",
      thumbnail: null,
      title: "",
      description: "",
    })
  }),
  async created() {
    let series = this.series[this.$route.params.id];
    if (!series) {
      await this.$store.dispatch('admin/fetchSeries', {id: [this.$route.params.id]});
      series = this.series[this.$route.params.id];
    }
    this.refresh(series);
  },
  computed: {
    ...mapGetters({
      series: "admin/series",
      seriesById: "admin/seriesById"
    })
  },
  methods: {
    refresh(series) {
      this.form.keys().forEach(key => {
        this.form[key] = series[key];
      });
    },
    update() {
      // Update the store
      let vm = this;
      this.$store.dispatch("admin/updateSeries", {form: this.form, params: {id: this.$route.params.id}}).then(() => {
        // Update the form
        let series = vm.series[vm.$route.params.id];
        vm.refresh(series);
      });
    }
  }
};
</script>
